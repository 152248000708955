@import "../../../../styles/mixins";
.up {
  &-container {
    padding: var(--size-65px) var(--size-16px) 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &-header--agent-mode {
    color: #c07c99;
  }
  &-locations {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    :global(.infinite-scroll-component) {
      min-height: calc(100vh - 9rem);
      display: flex;
      flex-direction: column;
    }
  }
}
