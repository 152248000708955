.up {
  &-form {
    margin-top: var(--size-20px);
    &-input {
      border: 0;
      border-radius: 0;
      background-color: #fff;
      font-weight: 400;
      color: #000000;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
