@import "../../../../styles/details";
@import "../../../../styles/mixins";

.up {
  &-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  &-card {
    padding: var(--size-65px) var(--size-16px) var(--size-16px);
    margin-bottom: var(--size-20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    &-avatar {
      margin-bottom: var(--size-13px);
      &-none {
        width: 3.813rem;
      }
    }
    &-name {
      margin-bottom: var(--size-9px);
      font-weight: 700;
      font-size: var(--size-20px);
      line-height: var(--size-24px);
      color: #333333;
    }
    &-edit {
      @include fixedContainer;
      position: absolute;
      top: var(--size-56px);
      display: flex;
      justify-content: flex-end;
      padding-right: var(--size-16px);

      &-button {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-14px);
        color: #039be5;
        padding: 0 var(--size-3px);
        text-transform: none;
        min-width: 0;
        flex-shrink: 0;
      }
    }
  }
  &-content {
    @include up-content-details;
  }
  &-locations {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 var(--size-16px);
    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
      margin-bottom: var(--size-20px);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-add {
      border: 1px solid #e3edff;
      background-color: #fff;
      border-radius: var(--size-5px);
      padding: var(--size-4px);
      margin-left: var(--size-16px);
      color: #2b3f6c;
    }
  }
  &-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-read-more {
    font-size: var(--size-14px);
    margin-top: var(--size--14px);
  }
  &-w-full {
    width: 100%;
  }
}
