@import "../../../styles/mixins";

$width: 6.25rem;
.up {
  &-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    z-index: 1200;
    background-color: #fff;
  }
  &-buttons {
    padding: 0 var(--size-10px);
    color: #304378;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    &-button {
      margin: 0 var(--size-6px);
      min-width: 0;
      padding: var(--size-8px) 4%;
      border-color: #e3edff;
      border-radius: var(--size-8px);
      font-size: var(--size-14px);
      text-transform: initial;
      line-height: var(--size-18px);
      background-color: #fff;
    }
  }
  &-drawer {
    &-block {
      position: relative;
      top: calc($width * -1);
      background-color: #fff;
      box-shadow: 1px 0 var(--size-10px) 1px rgba(0, 0, 0, 0.05);
      border-top-left-radius: var(--size-20px);
      border-top-right-radius: var(--size-20px);
      @include fixedContainer;
    }

    &-paper {
      max-height: calc(90vh - $width);
      overflow: visible;
      height: 100%;
      pointer-events: all !important;
      visibility: visible !important;
      background-color: transparent;

      box-shadow: none;
    }

    &-root {
      z-index: 2200;
    }

    &-header {
      visibility: visible;
      height: 4.688rem;
      padding: var(--size-32px) var(--size-22px) var(--size-10px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date {
        font-weight: 600;
        font-size: var(--size-20px);
        line-height: var(--size-20px);
        color: #000000;
      }

      .appointment-button {
        color: #304378;
        font-weight: 400;
        font-size: var(--size-14px);
        line-height: 1;
        text-transform: none;
        background: #ffffff;
        border: 1px solid #e3edff;
        border-radius: var(--size-5px);
        height: 2.188rem;
      }
    }

    &-content {
      position: relative;
      z-index: 1;
      overflow: auto;
      background: #fff;
      padding: var(--size-40px) var(--size-10px) 0;
      height: calc(90vh - 4.688rem);
    }
  }
  &-puller {
    position: absolute;
    width: 2.75rem;
    height: var(--size-3px);
    top: 0;
    right: 0;
    background: #e5e5e5;
    border-radius: var(--size-20px);
    top: var(--size-14px);
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
