.up-warning {
  text-align: center;
  padding: var(--size-50px) 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-button {
    margin-top: var(--size-40px);
    background: #38bb98;
    padding: 0 var(--size-18px);
    font-style: normal;
    font-weight: 700;
    font-size: var(--size-14px);
    color: #f7fffd;
    text-transform: capitalize;
    flex-shrink: 0;
    box-shadow: none;
    line-height: 1.2;
    height: 2.188rem;

    &:hover {
      background: #38bb98;
    }
  }

  &-test {
    padding: 0 0 var(--size-20px) 0;
    text-align: center;
  }
}
