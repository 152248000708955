.up {
  &-form {
    display: flex;
    flex-direction: column;
    &-description {
      opacity: 0.6;
      font-size: var(--size-14px);
    }
    &-field {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--size-30px);
    }
    &-label {
      padding-bottom: var(--size-8px);
    }
    &-button {
      margin: 0;
      min-width: 0;
      padding: var(--size-8px) 4%;
      border-radius: var(--size-8px);
      font-size: var(--size-14px);
      text-transform: initial;
      line-height: var(--size-18px);
      background: #4d6ca9;
      color: #fff;
      &:hover {
        background: #4d6ca9;
      }
    }
  }
}
