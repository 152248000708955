@mixin up-content-details {
  padding: 0 var(--size-16px);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--size-20px);

  &-label {
    font-weight: 400;
    font-size: var(--size-12px);
    color: #aaaaaa;
  }
  &-icons {
    display: flex;
    margin: var(--size-10px) var(--size--4px) var(--size-20px);
    > * {
      margin: 0 var(--size-4px);
      display: flex;
    }
  }
  &-value {
    font-weight: 400;
    font-size: var(--size-14px);
    line-height: 2;
    border-bottom: 1px solid #e3edff;
    padding-bottom: var(--size-6px);
    margin-bottom: var(--size-10px);
    color: #333333;
    display: flex;
    flex-wrap: wrap;
    padding-left: var(--size-16px);
    padding-right: var(--size-16px);
    margin-left: var(--size--16px);
    margin-right: var(--size--16px);
  }
  a,
  span {
    color: #039be5;
    transition: 0.2s linear 0s;
    margin-right: var(--size-8px);
    cursor: pointer;
    &:hover {
      color: #039be5;
    }
  }
}
