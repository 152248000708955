.up {
  &-grid {
    &-item {
      width: calc(50% - var(--size-20px));
      flex-shrink: 0;
      margin: 0 var(--size-4px);
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &--header {
        padding: 0 var(--size-5px) var(--size-5px);
        height: var(--size-20px);
        font-weight: 400;
        font-size: var(--size-12px);
        color: #aaaaaa;

        &.missed {
          color: #fb0707;
        }
        & .high {
          color: #fb0707;
        }
      }

      .description {
        font-weight: 300;
        font-size: var(--size-14px);
        line-height: var(--size-20px);
        color: #000000;
        padding: var(--size-7px);
        background: #ffffff;
        border: 1px solid rgba(77, 108, 169, 0.09);
        box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
        border-radius: var(--size-5px);
        flex-grow: 1;
        .content {
          padding: 0 var(--size-6px);
          margin-bottom: var(--size-20px);
        }
        .status {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          color: #00b383;
          img {
            margin-right: var(--size-5px);
          }
        }
      }
      .title {
        font-weight: 600;
        padding: 0 var(--size-6px);
        margin-bottom: var(--size-6px);
      }
    }
  }
}
