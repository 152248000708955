@import "variables";

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    Lato,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}
@media screen and (max-width: 400px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 300px) {
  html {
    font-size: 10px;
  }
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  word-break: break-word;
}

img {
  object-fit: cover;
}

input[type="number"] {
  appearance: textfield;
}
.up-truncate-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.router-hidden {
  opacity: 0;
  pointer-events: none;
  z-index: -10;
  height: 0;
  position: absolute;
  top: -6.25rem;
}
.up-no-items {
  text-align: center;
  padding-top: var(--size-20px);
  padding-bottom: var(--size-20px);
}

@import "toastify";
@import "calendar";
@import "range-calendar";
@import "mui";
