@import "../../../../styles/mixins";
.up {
  &-locations {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &-scrollable {
      max-height: calc(100vh - 8.5rem);
      overflow: auto;
      flex-grow: 1;
    }
    &-item {
      border-bottom: 1px solid #e0e0e0;
      padding: var(--size-14px) var(--size-65px) var(--size-14px) 0;
      color: #333333;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 4rem;
      cursor: pointer;
      transition: 0.2s linear 0s;
      &.active {
        background-color: #e9f9df;
      }
    }
    &-block {
      margin-bottom: var(--size-16px);
      > * {
        padding-left: var(--size-10px);
      }
    }
    &-letter {
      font-family: "Nunito", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: var(--size-14px);
      line-height: var(--size-19px);
      color: #aaaaaa;
      text-transform: uppercase;
    }
    &-title {
      font-weight: 700;
      font-size: var(--size-14px);
      line-height: var(--size-17px);
      margin-bottom: var(--size-8px);
    }
    &-details {
      font-weight: 400;
      font-size: var(--size-12px);
      line-height: var(--size-14px);
      opacity: 0.5;
      word-break: break-word;
      margin-bottom: var(--size-8px);
    }
    &-logo {
      width: var(--size-56px);
      height: var(--size-56px);
      object-fit: contain;
      object-position: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto var(--size-10px);
    }
  }
  &-search {
    @include searchStyles;
  }
}
