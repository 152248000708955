.up {
  &-form {
    margin-bottom: var(--size-15px);
    width: 100%;
    &-title {
      font-weight: 400;
      line-height: var(--size-20px);
      color: #aaaaaa;
      margin: 0 var(--size-16px) var(--size-8px);
    }
    &-input {
      border-radius: 0;
      background-color: #fff;
      color: #000000;
      &:before,
      &:after {
        display: none;
      }
      padding: var(--size-16px);
    }
  }
}
