.up {
  &-container {
    background-color: #fafafc;
    min-height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--size-65px) var(--size-16px) 8vh;
    img {
      width: 8.188rem;
    }
  }
}
