.up {
  &-agents {
    &-card {
      display: flex;
      align-items: center;
      height: var(--size-65px);
      border: 1px solid #F4F2FF;
      border-radius: var(--size-5px);
      background: #fff;
      box-shadow: var(--size-3px) var(--size-5px) var(--size-7px) #f8f0f0;
      cursor: pointer;

      &--img-wrapper {
        margin: 0 var(--size-20px) 0 var(--size-15px);
        width: var(--size-48px);
        height: var(--size-48px);
      }

      &--body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: var(--size-40px);
        font-size: var(--size-14px);
        font-weight: 700;

        &--email {
          font-size: var(--size-11px);
          color: #9b9999;
        }
      }
    }
  }
}
