@import "../../../../styles/mixins";
.up {
  &-container {
    padding: var(--size-65px) var(--size-16px) var(--size-40px);
  }

  &-location {
    &-name {
      font-weight: 700;
      font-size: var(--size-15px);
      line-height: var(--size-16px);
      color: #333333;
      margin-bottom: var(--size-16px);
    }
    &-address {
      font-weight: 400;
      font-size: var(--size-13px);
      line-height: var(--size-20px);
      color: #333333;
      margin-bottom: var(--size-25px);
      max-width: 50%;
    }
  }

  &-search {
    @include searchStyles;
    &-root {
      margin-bottom: 0;
    }
  }

  &-content {
    margin-top: var(--size-20px);
    &-card {
      margin-bottom: var(--size-20px);
      background: #ffffff;
      border: 1px solid #f4f2ff;
      box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
      border-radius: var(--size-5px);
      font-weight: 300;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: rgba(0, 0, 0, 0.7);
      padding: var(--size-6px) var(--size-12px);
      cursor: pointer;
      &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: var(--size-10px);
        align-items: flex-start;
        &-left {
          display: flex;
          flex-direction: column;
        }
        &-task,
        &-location {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          color: #aaaaaa;
        }
        &-date {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          color: #aaaaaa;
          margin-left: auto;
        }
      }
      &-footer {
        margin-top: var(--size-10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-author {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          color: #aaaaaa;
        }
      }
    }
  }
  &-fab {
    &-root {
      @include fabStyles;
    }
  }
}
