$width: calc(100vh - 26.563rem);
@import "../../../../../styles/mixins";

.up {
  &-container {
    padding: var(--size-65px) 0 var(--size-40px);
  }

  &-drawer {
    &-block {
      position: relative;
      top: calc($width * -1);
      background-color: #fff;
      box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.05);
      border-top-left-radius: var(--size-20px);
      border-top-right-radius: var(--size-20px);
      @include fixedContainer;
    }

    &-paper {
      max-height: calc(90vh - $width);
      overflow: visible;
      height: 100%;
      pointer-events: all !important;
      visibility: visible !important;
      background-color: transparent;
      box-shadow: none;
    }

    &-root {
      z-index: 1199;
    }

    &-header {
      visibility: visible;
      height: 4.688rem;
      padding: 2.25rem var(--size-22px) var(--size-10px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date {
        font-weight: 600;
        font-size: var(--size-20px);
        line-height: var(--size-20px);
        color: #000000;
      }

      .appointment-button {
        color: #304378;
        font-weight: 400;
        font-size: var(--size-14px);
        line-height: 1;
        text-transform: none;
        background: #ffffff;
        border: 1px solid #e3edff;
        border-radius: var(--size-5px);
        height: 2.188rem;
        padding: var(--size-8px) var(--size-16px);
      }
    }

    &-content {
      position: relative;
      z-index: 1;
      overflow: auto;
      background: #fff;
      padding: var(--size-16px);
      height: calc(90vh - 4.688rem);
    }
  }

  &-puller {
    position: absolute;
    width: 2.75rem;
    height: var(--size-3px);
    background: #e5e5e5;
    border-radius: var(--size-20px);
    top: var(--size-14px);
    margin: auto;
    left: 0;
    right: 0;
    cursor: pointer;

    &-btn {
      position: absolute;
      height: var(--size-30px);
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  &-card {
    margin-bottom: var(--size-20px);
  }

  &-header--agent-mode {
    color: #c07c99;
  }
}.active-day {
  background-color: #e2e2e2 !important;
}
