@import "../../../../styles/mixins";
@import "../../../../styles/mui-switch";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-card {
    padding: 3.438rem var(--size-16px) var(--size-16px);
    margin-bottom: var(--size-30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
      179.89deg,
      rgba(227, 237, 255, 0.3) 0.1%,
      rgba(97, 188, 248, 0.35) 99.91%
    );
  }

  &-form {
    &-title {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-company {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }

    &-address {
      margin-bottom: var(--size-30px);
    }
    &-agents {
      margin-bottom: var(--size-40px);
    }

    &-geolocation {
      margin-bottom: var(--size-40px);
    }

    &-select {
      margin-bottom: var(--size-40px);
      width: 100%;
      &-input {
        @include inputStyles;
      }

      &-option {
        padding-left: var(--size-20px);
      }
    }

    &-work-schedule {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      pointer-events: none;
      -webkit-transition: all 0.2s linear 0ms;
      transition: all 0.2s linear 0ms;
      padding-top: var(--size-4px);

      &.expanded {
        opacity: 1;
        max-height: 45rem;
        pointer-events: auto;
        overflow: auto;
      }
    }

    &-has-time-schedule {
      padding: 0 var(--size-14px);
      margin-bottom: var(--size-30px);

      &--label {
        margin-right: var(--size-20px);
      }

      .up-switch {
        @include mui-switch;
      }
    }

    &-working-hours {
      margin-bottom: var(--size-40px);
      display: flex;
      flex-direction: column;
      &-title {
        font-weight: 400;
        font-size: var(--size-12px);
        line-height: var(--size-20px);
        color: #aaaaaa;
        margin: 0 var(--size-16px) var(--size-8px);
      }
      &-block {
        background: #ffffff;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: var(--size-26px) var(--size-16px);
      }
    }

    &-phoneNumbers,
    &-emails {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
        padding: var(--size-10px) var(--size-14px) !important;
      }
      &-root {
        margin-bottom: var(--size-10px);
      }
      &-add {
        color: #304378;
        border-color: #e3edff;
        background: #fff;
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        width: 100%;
        justify-content: flex-start;
        text-transform: none;
        font-weight: 400;
        font-size: var(--size-14px);
        &:hover {
          border-left: 0;
          border-radius: 0;
          border-right: 0;
        }
      }
    }
  }
  &-modal {
    background-color: #fafafc;
    height: 100vh;
    @include fixedContainer;
    &-company {
      &-item {
        font-weight: 700;
        font-size: var(--size-14px);
        line-height: var(--size-17px);
        color: #333333;
        padding: var(--size-16px);
        border-top: 1px solid #e0e0e0;
        &:last-child {
          border-bottom: 1px solid #e0e0e0;
        }
        &.active {
          background-color: #e9f9df;
        }
      }
    }
  }
}
