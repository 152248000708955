@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-card {
    padding: var(--size-16px);
    margin-bottom: var(--size-30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
  }

  &-form {
    &-title {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }

    &-address {
      margin-bottom: var(--size-30px);
    }

    &-phoneNumbers {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
        padding: var(--size-10px) var(--size-14px) !important;
      }
      &-root {
        margin-bottom: var(--size-10px);
      }
      &-add {
        color: #304378;
        border-color: #e3edff;
        background: #fff;
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        width: 100%;
        justify-content: flex-start;
        text-transform: none;
        font-weight: 400;
        font-size: var(--size-14px);
        &:hover {
          border-left: 0;
          border-radius: 0;
          border-right: 0;
        }
      }
    }

    &-information {
      &-input {
        @include inputStyles;
      }
    }
  }
}
