@import "../../../../styles/mixins";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}

.up {
  &-publish {
    &-tasks {
      margin: 0 var(--size-16px) var(--size-8px);
      font-weight: 300;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #000000;
      background: #ffffff;
      border: 1px solid #f4f2ff;
      box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
      border-radius: var(--size-5px);
      padding: var(--size-12px);
      transition: backgroundColor 0.2s linear 0s;
      cursor: pointer;
      &.active {
        background-color: #e9f9df;
      }
    }
  }
  &-form {
    &-task {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
  }

  &-modal {
    background-color: #fafafc;
    @include fixedContainer;
    height: 100vh;
    &-content {
      padding: var(--size-16px);
    }
  }
}
