.up {
  &-route-start {
    background: #EEFEFF;
    border: 1px solid #C2ECEE;
    box-shadow: none;
    border-radius: var(--size-5px);
    width: 100%;
    padding: var(--size-8px);
    display: flex;
    flex-direction: column;
    color: #4d6ca9;
    margin-bottom: var(--size-20px);
    font-weight: 400;
    font-size: var(--size-12px);
    line-height: var(--size-20px);

    &-content {
      padding: var(--size-4px) var(--size-8px) !important;
      margin-bottom: var(--size-6px);
      display: flex;
      flex-direction: column;
    }
    &-actions {
      padding: 0 var(--size-8px);
      display: flex;
    }
    &-button {
      border: 1px solid #e3edff;
      border-radius: 1000px;
      padding: var(--size-5px) 5%;
      font-weight: 600;
      font-size: var(--size-14px);
      line-height: var(--size-16px);
      color: #304378;
      text-transform: none;
      white-space: nowrap;
      margin: 0 var(--size-8px) 0 0;
      min-width: 0;
      &-right {
        color: #00b383;
        border-color: #b5e5d8;
        border-radius: 1000px;
        padding: var(--size-5px) 5%;
        font-weight: 600;
        font-size: var(--size-14px);
        line-height: var(--size-16px);
        text-transform: none;
        white-space: nowrap;
        margin-left: auto;
      }
    }
  }
}
