.up {
  &-container {
    padding: var(--size-65px) var(--size-16px) 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &-header--agent-mode {
    color: #c07c99;
  }
  &-warning {
    display: flex;
    flex-direction: column;
  }
  &-warning-test {
    text-align: center;
  }
  &-warning-loading {
    text-align: center;
    position: relative;
    padding: 0 var(--size-16px);
    &::after {
      position: absolute;
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: "\2026";
      width: 0px;
    }
  }

  @keyframes ellipsis {
    to {
      width: var(--size-16px);
    }
  }
}
