.up {
  &-container {
    padding: var(--size-65px) var(--size-16px) var(--size-30px);
  }
  &-accordion {
    &-root {
      box-shadow: none;
      border: 1px solid #e3edff;
    }
  }
  &-button {
    margin-top: var(--size-20px);
    text-transform: none;
    font-size: var(--size-18px);
  }
}
