.up {
  &-dialog {
    &-actions {
      &-button {
        &.contained {
          background: #4d6ca9;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-20px);
          color: #fff;
          text-transform: none;
          white-space: nowrap;
          margin-left: var(--size-8px);
        }
        &.outlined {
          background: #ffffff;
          border: 1px solid #e3edff;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-20px);
          color: #707a8d;
          text-transform: none;
          white-space: nowrap;
        }
      }
    }
  }
}
