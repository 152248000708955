@import "../../../../styles/mui-switch";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-form {
    &-title {
      margin-bottom: var(--size-40px);

      &-input {
        @include inputStyles;
      }
    }

    &-location {
      margin-bottom: var(--size-40px);

      &-input {
        @include inputStyles;
      }
    }

    &-radio {
      margin-bottom: var(--size-40px);

      &-group {
        background: #ffffff;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: var(--size-4px) var(--size-30px);
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &-label {
        font-weight: 400;
        font-size: var(--size-14px);
        line-height: var(--size-20px);
        color: #707070;
      }
    }

    &-date {
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      padding: var(--size-20px);

      .up-switch {
        @include mui-switch;
      }

      &-expected-duration {
        display: flex;
        align-items: baseline;
        padding-top: 1px;
        border-bottom: 1px solid #e3edff;
        margin-bottom: var(--size-10px);

        &--textfield {
          height: var(--size-65px);

          &--input-root {
            padding: var(--size-5px) 0 0 var(--size-8px);
            border-radius: var(--size-8px);
            border: 1px solid #e0e0e0;
            font-size: var(--size-14px);
          }
          &--input {
            width: 9.375rem;
          }
        }

        &--label {
          font-style: normal;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-16px);
          color: #000000;
          margin-right: var(--size-10px);
        }
      }

      &-header {
        display: flex;
        align-items: center;
        padding-bottom: var(--size-40px);
        border-bottom: 1px solid #e3edff;
        margin-bottom: var(--size-10px);

        &-label {
          font-style: normal;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-16px);
          color: #000000;
          margin-right: var(--size-10px);
        }
      }

      &-tabs {
        display: flex;
        margin-bottom: var(--size-30px);

        &-block {
          display: flex;
          flex-direction: column;

          &:first-child {
            margin-right: auto;
          }
        }

        &-label {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          margin-bottom: var(--size-5px);
          color: #aaaaaa;
        }

        &-field {
          padding: var(--size-9px) var(--size-14px);
          background: #f4f5f7;
          border-radius: var(--size-5px);
          font-weight: 400;
          font-size: var(--size-14px);
          line-height: var(--size-16px);
          color: #707070;
          transition: 0.2s linear 0s;
          cursor: pointer;

          &.active {
            background-color: #e3edff;
            color: #000;
          }
        }

        &-divider {
          font-weight: 400;
          font-size: var(--size-32px);
          line-height: 1;
          text-align: center;
          color: #000000;
          margin: var(--size-24px) var(--size-16px) 0;
        }
      }
    }
  }

  &-modal {
    &-location {
      background-color: #fafafc;
      height: 100vh;

      &-content {
        padding: var(--size-16px);
      }
    }

    background-color: #fafafc;
    height: 100vh;
    overflow: auto;

    &-company {
      &-item {
        font-weight: 700;
        font-size: var(--size-14px);
        line-height: var(--size-17px);
        color: #333333;
        padding: var(--size-16px);
        border-top: 1px solid #e0e0e0;

        &:last-child {
          border-bottom: 1px solid #e0e0e0;
        }

        &.active {
          background-color: #e9f9df;
        }
      }
    }
  }
}
