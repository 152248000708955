@mixin inputStyles {
  line-height: 1.2;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-container {
    background-color: #fafafc;
    min-height: 100vh;
    padding: var(--size-65px) 0 var(--size-40px);
  }
  &-form {
    &-description {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
        font-size: var(--size-14px);
        line-height: 1;
        padding: var(--size-18px) var(--size-16px);
      }
    }
    &-block {
      padding: var(--size-30px) var(--size-16px);
      background-color: #fff;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
    &-picker {
      border-bottom: 1px solid #e3edff;
      margin-bottom: var(--size-20px);
    }
    &-time {
      &-label {
        font-weight: 400;
        font-size: var(--size-12px);
        color: #aaaaaa;
        margin-bottom: var(--size-30px);
        display: flex;
      }
      &-tabs {
        display: flex;
        margin-bottom: var(--size-16px);
        &-block {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        &-label {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          margin-bottom: var(--size-5px);
          color: #aaaaaa;
        }
        &-field {
          padding: var(--size-9px) var(--size-14px);
          background: #f4f5f7;
          border-radius: var(--size-5px);
          font-weight: 400;
          font-size: var(--size-14px);
          line-height: var(--size-16px);
          color: #707070;
          transition: 0.2s linear 0s;
          cursor: pointer;
          &.active {
            background-color: #e3edff;
            color: #000;
          }
        }
        &-divider {
          font-weight: 400;
          font-size: var(--size-32px);
          line-height: 1;
          text-align: center;
          color: #000000;
          margin: var(--size-24px) var(--size-16px) 0;
        }
      }
    }
  }
}
