.up {
  &-container {
    padding: var(--size-65px) 0 var(--size-40px);
  }
  &-recent {
    padding: 0 0 0 var(--size-16px);
    margin-bottom: var(--size-25px);
    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
      margin-bottom: var(--size-20px);
    }
    &-grid {
      display: flex;
      margin-left: var(--size--4px);
      width: 100%;
      overflow-y: auto;
      padding-bottom: var(--size-10px);
      padding-right: var(--size-10px);
      &-item {
        width: calc(50% - var(--size-20px));
        flex-shrink: 0;
        margin: 0 var(--size-4px);
        position: relative;
        background: #ffffff;
        border: 1px solid #e3edff;
        border-radius: var(--size-5px);
        font-weight: 600;
        font-size: var(--size-13px);
        line-height: var(--size-20px);
        color: #707070;
        padding: var(--size-12px) var(--size-12px) var(--size-22px);
        transition: 0.2s linear 0s;
        cursor: pointer;
        &.active {
          background-color: #e3edff;
        }
      }
    }
  }
  &-address {
    margin-bottom: var(--size-30px);
    &-button {
      color: #304378;
      border-color: #e3edff;
      background: #fff;
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      width: 100%;
      justify-content: flex-start;
      text-transform: none;
      font-weight: 400;
      font-size: var(--size-14px);
      &:hover {
        border-left: 0;
        border-radius: 0;
        border-right: 0;
      }
    }
    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
      margin-bottom: var(--size-30px);
      padding: 0 var(--size-16px);
    }
  }

  &-time {
    margin-bottom: var(--size-25px);
    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
      margin-bottom: var(--size-16px);
      padding: 0 var(--size-16px);
    }
    &-block {
      padding: 0 var(--size-16px);
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      background-color: #fff;
    }
  }
}
