@import "../../../../styles/mixins";
.up {
  &-avatar {
    margin-bottom: var(--size-7px);
    display: flex;
    align-items: center;
    flex-direction: column;
    &-root {
      font-weight: 700;
      font-size: var(--size-24px);
      color: #ffffff;
    }
  }
  &-drawer {
    border-radius: var(--size-20px) var(--size-20px) 0 0;
    background-color: #fbfbfb;

    @include fixedContainer;
  }
  &-puller {
    position: absolute;
    width: 2.75rem;
    height: var(--size-3px);
    right: 0;
    background: #e5e5e5;
    border-radius: var(--size-20px);
    top: var(--size-14px);
    margin: auto;
    left: 0;
    right: 0;
  }
  &-list {
    padding: var(--size-50px) 0 var(--size-40px);

    &-icon {
      min-width: auto;
      margin-right: var(--size-14px);
    }
    &-img {
      width: var(--size-24px);
    }
    &-item {
      padding: var(--size-13px) var(--size-30px);
      display: flex;
      width: 100%;
      align-items: center;
      label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    &-text {
      margin: 0;
      font-weight: 600;
      font-size: var(--size-16px);
      color: #000000;
    }
  }
  &-upload-img {
    font-weight: 400;
    font-size: var(--size-14px);
    line-height: var(--size-16px);
    text-align: center;
    margin-top: var(--size-14px);
    color: #039be5;
    cursor: pointer;
    background-color: white;
    border: 1px solid #e3edff;

    &:hover {
      background-color: #efefef;
    }
  }

  &-dialog {
    &-canvas {
      position: absolute;
      left: 0;
      z-index: -1;
    }
    &-content {
      overflow-y: initial;
      &-item {
        position: relative;
        img {
          max-height: calc(100vh - 10rem);
        }
      }
    }
    &-actions {
      &-button {
        &.contained {
          background: #4d6ca9;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-20px);
          color: #fff;
          text-transform: none;
          white-space: nowrap;
        }
        &.outlined {
          background: #ffffff;
          border: 1px solid #e3edff;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-20px);
          color: #707a8d;
          text-transform: none;
          white-space: nowrap;
        }
      }
    }
  }
}
