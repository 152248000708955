@import "../../../../styles/details";
@import "../../../../styles/mixins";

.up {
  &-card {
    padding: 3.438rem var(--size-16px) var(--size-22px);
    background: linear-gradient(
      90.07deg,
      rgba(97, 188, 248, 0.27) 21.36%,
      rgba(255, 189, 244, 0.17) 99.93%
    );

    .place-name {
      font-weight: 700;
      font-size: var(--size-24px);
      line-height: var(--size-30px);
      color: #333333;
      margin-bottom: var(--size-30px);
      display: flex;
      align-items: flex-start;
    }
    &-edit {
      @include fixedContainer;
      position: absolute;
      top: var(--size-56px);
      display: flex;
      justify-content: flex-end;
      padding-right: var(--size-16px);

      &-button {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-14px);
        color: #039be5;
        padding: 0 var(--size-3px);
        text-transform: none;
        min-width: 0;
        flex-shrink: 0;
      }
    }

    .travel-time {
      font-size: var(--size-13px);
      margin-bottom: var(--size-20px);
      display: flex;
      align-items: center;
      color: #333333;
      > img {
        margin-right: var(--size-10px);
        width: var(--size-16px);
      }
    }
    .call-time {
      display: flex;
      align-items: center;
      margin-bottom: var(--size-16px);
      > img {
        margin-right: var(--size-10px);
        width: var(--size-16px);
      }
      font-size: var(--size-14px);
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .address {
        font-size: var(--size-13px);
        line-height: var(--size-20px);
        color: #333333;
        margin-right: 20%;
      }
      .status {
        font-weight: 400;
        font-size: var(--size-12px);
        line-height: var(--size-20px);
        color: #00b383;
        white-space: nowrap;
        text-align: right;
        img {
          margin-right: var(--size-5px);
        }
      }
    }
    &-active {
      padding: var(--size-10px) var(--size-16px) var(--size-5px);
      background: linear-gradient(
        90deg,
        rgb(228, 248, 242) 0%,
        rgb(251, 241, 251) 100%
      );
      display: flex;
      align-items: center;
      margin-bottom: var(--size-16px);
      &-button {
        background: #38bb98;
        color: #fff;
        padding: 0 var(--size-18px);
        font-style: normal;
        font-weight: 700;
        font-size: var(--size-14px);
        line-height: var(--size-28px);
        color: #f7fffd;
        text-transform: capitalize;
        flex-shrink: 0;
        box-shadow: none;
        line-height: 1.2;
        height: 2.188rem;
        margin-left: auto;
        &:hover {
          background: #38bb98;
        }
      }
      &-radio {
        &-title {
          font-style: normal;
          font-weight: 400;
          font-size: var(--size-13px);
          line-height: var(--size-20px);
          color: #707070;
        }
        &-label {
          font-weight: 400;
          font-size: var(--size-13px);
          line-height: var(--size-20px);
          color: #707070;
        }
      }
    }
    &-blocked {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      text-align: center;
      color: #c82727;
    }
    &.blocked {
      background: linear-gradient(
        359.33deg,
        rgba(229, 30, 42, 0.05) 0.62%,
        rgba(255, 255, 255, 0.05) 68.5%
      );
    }
  }

  &-buttons {
    padding: 0 var(--size-10px);
    color: #304378;
    display: flex;
    justify-content: flex-start;
    margin-top: var(--size-16px);
    margin-bottom: var(--size-16px);
    flex-wrap: wrap;

    &-button {
      margin: 0 var(--size-6px);
      min-width: 0;
      padding: var(--size-8px) 4%;
      border-color: #e3edff;
      border-radius: var(--size-8px);
      font-size: var(--size-14px);
      text-transform: initial;
      line-height: var(--size-18px);
      background-color: #fff;
      margin-bottom: var(--size-10px);
      &.unskip {
        color: #00b383;
        border-color: #b5e5d8;
      }
    }
  }
  &-content {
    @include up-content-details;
  }
  &-w-full {
    width: 100%;
  }
}
