.up-card {
  border: 1px solid #f4f5f7;
  box-shadow: 0 0 var(--size-15px) rgba(0, 0, 0, 0.05);
  border-radius: var(--size-5px);
  width: 100%;
  padding: var(--size-8px);
  display: flex;
  flex-direction: column;
  &-header {
    padding: 0;
    &-title {
      font-weight: 600;
      font-size: var(--size-12px);
      display: flex;
      padding: 0 var(--size-8px);
    }
    &-action {
      color: #b2b5be;
      font-weight: 600;
      font-size: var(--size-12px);
      display: flex;
      align-items: center;
      margin: 0;
      span {
        // width: var(--size-20px);
        // height: var(--size-20px);
        // border-radius: 50%;
        // background-color: #b2b5be;
        // font-weight: 600;
        // font-size: var(--size-14px);
        // color: #ffffff;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-left: var(--size-5px);

        // &.priority-1 {
        //   background-color: #ffdf4d;
        // }
        // &.priority-2 {
        //   background-color: #71ae9e;
        // }
        // &.priority-3 {
        //   background-color: #8495b9;
        // }
      }
    }
  }
  &-content {
    padding: var(--size-4px) var(--size-8px) !important;
    color: #707070;
    font-weight: 600;
    margin-bottom: var(--size-18px);
    .place-name {
      font-weight: 700;
      font-size: var(--size-18px);
      color: #333333;
      margin-bottom: var(--size-12px);
      text-decoration: underline;
      cursor: pointer;
    }

    .address {
      font-size: var(--size-13px);
      color: #707070;
      margin-bottom: var(--size-16px);
    }

    .travel-time {
      font-size: var(--size-13px);
      margin-bottom: var(--size-16px);
      display: flex;
      align-items: center;
      > img {
        margin-right: var(--size-10px);
        width: var(--size-16px);
      }
    }
    .plone-number {
      font-size: var(--size-13px);
      > a {
        color: #039be5;
        transition: 0.2s linear 0s;
        margin-right: var(--size-8px);
        cursor: pointer;
      }
    }
    .call-time {
      display: flex;
      align-items: center;
      margin-bottom: var(--size-16px);
      > img {
        margin-right: var(--size-10px);
        width: var(--size-16px);
      }
      font-size: var(--size-14px);
    }
  }
  &-actions {
    padding: 0 var(--size-8px);
    flex-wrap: wrap;
  }
  &-button {
    background: #ffffff;
    border: 1px solid #e3edff;
    border-radius: 1000px;
    padding: var(--size-5px) 5%;
    font-weight: 600;
    font-size: var(--size-14px);
    line-height: var(--size-16px);
    color: #304378;
    text-transform: none;
    white-space: nowrap;
    margin: 0 var(--size-8px) var(--size-10px) 0;
    min-width: 0;
    &:last-child {
      margin-right: 0;
    }
    &.unskip {
      color: #00b383;
      border-color: #b5e5d8;
    }
  }

  &.urgent {
    .up-card-header {
      &-title {
        color: #fb0707;
        text-transform: uppercase;
      }
    }
  }
  &.pastdue {
    .up-card-header {
      &-title {
        color: #6d29f6;
        text-transform: uppercase;
      }
    }
  }
  &.appointment {
    border-right: 3px solid rgba(3, 155, 229, 0.68);
    .up-card-header {
      &-title {
        color: #b2b5be;
      }
    }
  }
  &.missed {
    border-right: 3px solid #ff6ce8;
    .up-card-header {
      &-title {
        color: #ff6ce8;
      }
    }
  }
  &.overdue {
    border-right: 3px solid #ff0901;
    .up-card-header {
      &-title {
        color: #ff0901;
      }
    }
  }
  &.in-progress {
    border-right: 3px solid #4cd964;
    .up-card-header {
      &-title {
        color: #4cd964;
      }
    }
  }
  &.active {
    background-color: #e9f9df;
  }
}
