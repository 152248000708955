@import "../../../../styles/mixins";
@import "../../../../styles/details";

.up {
  &-card {
    padding: var(--size-56px) var(--size-16px) var(--size-22px);
    background: linear-gradient(179.89deg, #e3edff 21.91%, #f4f2ff 99.65%);
    margin-bottom: var(--size-20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    &-name {
      margin-bottom: var(--size-5px);
      font-weight: 700;
      font-size: var(--size-20px);
      line-height: var(--size-24px);
      color: #333333;
    }
    &-position {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      text-align: center;
      color: #304378;
    }
    &-edit {
      @include fixedContainer;
      position: absolute;
      display: flex;
      justify-content: flex-end;
      padding: 0 var(--size-20px);

      &-button {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-14px);
        color: #039be5;
        padding: 0 var(--size-3px);
        text-transform: none;
        min-width: 0;
        flex-shrink: 0;
      }
    }
  }

  &-content {
    @include up-content-details;
    &-row {
      display: flex;
      justify-content: space-between;
    }
  }

  &-special {
    padding-left: var(--size-16px);
    padding-bottom: var(--size-20px);
    &-grid {
      display: flex;
      margin-left: var(--size--4px);
      width: 100%;
      overflow-y: auto;
      padding-bottom: var(--size-10px);
      padding-right: var(--size-10px);
      margin-bottom: var(--size-10px);
      &-item {
        width: 70%;
        flex-shrink: 0;
        margin: 0 var(--size-4px);
        position: relative;
        background: #f4f5f7;
        border-radius: var(--size-5px);
        font-weight: 600;
        font-size: var(--size-13px);
        line-height: var(--size-20px);
        padding: var(--size-12px) var(--size-12px) var(--size-22px);
        display: flex;
        flex-direction: column;
      }
      &-date {
        color: #38bb98;
        font-weight: 700;
      }
      &-delete {
        cursor: pointer;
        width: var(--size-18px);
        margin-left: auto;
        margin-top: var(--size-5px);
      }

      &-no-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-button {
          color: #304378;
          background-color: #fff;
          border-color: #e3edff;
          font-weight: 400;
          font-size: var(--size-14px);
          text-transform: capitalize;
        }
      }
    }
    &-title {
      font-weight: 600;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #b2b5be;
      margin-bottom: var(--size-10px);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-add {
      border: 1px solid #e3edff;
      background-color: #fff;
      border-radius: var(--size-5px);
      padding: var(--size-4px);
      margin-right: var(--size-16px);
      color: #2b3f6c;
    }
  }
}
