.up {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--size-20px);
    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
    }
    &-chip {
      margin: 0 var(--size-10px);
      margin-right: auto;
      color: #707a8d;
      background-color: #efe7ff;
      font-size: var(--size-14px);
      height: var(--size-28px);
      &-label {
        padding: 0 var(--size-10px);
      }
    }
    &-add {
      border: 1px solid #e3edff;
      background-color: #fff;
      border-radius: var(--size-5px);
      padding: var(--size-2px);
      margin-right: var(--size-16px);
      margin-left: var(--size-16px);
      color: #2b3f6c;
    }
    &-link {
      cursor: pointer;
    }
  }
  &-grid {
    display: flex;
    margin-left: var(--size--4px);
    width: 100%;
    overflow-y: auto;
    padding-bottom: var(--size-10px);
    padding-right: var(--size-10px);
    &-item {
      width: calc(50% - var(--size-20px));
      flex-shrink: 0;
      margin: 0 var(--size-4px);
      display: flex;
      flex-direction: column;
      .date {
        padding: 0 var(--size-5px) var(--size-5px);
        font-weight: 400;
        font-size: var(--size-12px);
        color: #aaaaaa;
      }
      .description {
        font-weight: 300;
        font-size: var(--size-14px);
        line-height: var(--size-20px);
        color: #000000;
        padding: var(--size-7px);
        background: #ffffff;
        border: 1px solid rgba(77, 108, 169, 0.09);
        box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
        border-radius: var(--size-5px);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .content {
          padding: 0 var(--size-6px) 0;
          margin-bottom: var(--size-20px);
        }
        .author {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          font-size: var(--size-12px);
          color: #aaaaaa;
          margin-top: auto;
          .edit {
            min-width: 0;
            border: 1px solid #e3edff;
            padding: var(--size-7px);
            border-radius: var(--size-5px);
          }
        }
      }
    }
    &-no-items {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #333333;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-button {
        color: #304378;
        background-color: #fff;
        border-color: #e3edff;
        margin-top: var(--size-16px);
        font-weight: 400;
        font-size: var(--size-14px);
        text-transform: capitalize;
      }
    }
  }
}
