@import "../../../../styles/mixins";

.up {
  &-container {
    padding: var(--size-65px) var(--size-16px) var(--size-40px);
  }
  &-title {
    font-family:
      Lato,
      Oxygen,
      Ubuntu,
      Cantarell,
      Fira Sans,
      Droid Sans,
      Helvetica Neue,
      sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: var(--size-24px);
    color: #333333;
    margin-bottom: var(--size-20px);
  }

  &-location {
    &-name {
      font-weight: 700;
      font-size: var(--size-15px);
      line-height: var(--size-16px);
      color: #333333;
      margin-bottom: var(--size-16px);
    }
    &-address {
      font-weight: 400;
      font-size: var(--size-13px);
      line-height: var(--size-20px);
      color: #333333;
      margin-bottom: var(--size-25px);
      max-width: 50%;
    }
  }
  &-card {
    margin-bottom: var(--size-8px);
    font-weight: 300;
    font-size: var(--size-14px);
    line-height: var(--size-20px);
    color: #000000;
    background: #ffffff;
    border: 1px solid #f4f2ff;
    box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
    border-radius: var(--size-5px);
    padding: var(--size-12px);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--size-8px);
      font-size: var(--size-12px);
    }
    &-location {
      font-weight: 600;
      font-size: var(--size-12px);
      line-height: var(--size-20px);
      color: #039be5;
      transition: 0.2s linear 0s;
      cursor: pointer;
    }
    &-date {
      font-weight: 400;
      font-size: var(--size-12px);
      line-height: var(--size-20px);
      color: #aaaaaa;
      margin-left: auto;
    }
    &-title {
      font-weight: 700;
      margin-bottom: var(--size-4px);
    }
    &-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &-fab {
    &-root {
      @include fabStyles;
    }
  }
}
