@mixin inputStyles {
  line-height: 1.2;
  border-radius: var(--size-5px);
  background-color: #fff;
  border: 1px solid #e0e0e0;
  font-weight: 400;
  color: #000000;
  transition: 0.2s linear 0s;
  min-height: var(--size-56px);

  &:before,
  &:after {
    display: none;
  }
  &.focused {
    background-color: #fff;
    border-color: #039be5;
  }
  input {
    padding: var(--size-12px) var(--size-14px);
  }
}
.up {
  &-title {
    font-weight: 700;
    font-size: var(--size-24px);
    line-height: var(--size-36px);
    text-align: center;
    color: #304378;
    margin-bottom: var(--size-20px);
  }

  &-description {
    font-weight: 400;
    font-size: var(--size-12px);
    line-height: var(--size-14px);
    text-align: center;
    color: #707070;
    margin-bottom: var(--size-24px);
  }

  &-form {
    margin-bottom: var(--size-40px);
    &-password {
      margin-bottom: var(--size-20px);
      &-input {
        @include inputStyles;
      }
    }
    &-confirm-password {
      margin-bottom: var(--size-20px);
      &-input {
        @include inputStyles;
      }
    }
    &-button {
      font-weight: 800;
      font-size: var(--size-14px);
      text-align: center;
      color: #ffffff;
      padding: var(--size-10px) var(--size-30px);
      background-color: #304378;
      border-radius: var(--size-5px);
      margin: auto;
      display: flex;
      box-shadow: none;
      text-transform: uppercase;
      &:hover {
        background-color: #304378;
        box-shadow: none;
      }
    }
    &-link {
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      text-align: center;
      color: #039be5;
      margin: auto;
      display: block;
      margin-bottom: var(--size-40px);
      cursor: pointer;
    }
  }
}
