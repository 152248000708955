@import "../../../../styles/mixins";
.up {
  &-agents {
    &-container {
      max-height: calc(100vh - 7.5rem);
      overflow: auto;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  &-search {
    @include searchStyles;
  }
}
