@import "../../../styles/mixins";

.up {
  &-drawer {
    min-height: 50%;
    border-radius: var(--size-20px) var(--size-20px) 0 0;
    background-color: #fbfbfb;
    @include fixedContainer;
  }
  &-puller {
    position: absolute;
    width: 2.75rem;
    height: var(--size-3px);
    top: 0;
    right: 0;
    background: #e5e5e5;
    border-radius: var(--size-20px);
    top: var(--size-14px);
    margin: auto;
    left: 0;
    right: 0;
  }
  &-list {
    padding: var(--size-50px) 0;

    &-title {
      font-weight: 700;
      font-size: var(--size-16px);
      text-transform: uppercase;
      color: #000000;
      padding: 0 0 var(--size-15px) var(--size-30px);
    }
    &-icon {
      min-width: auto;
      margin-right: var(--size-14px);
    }
    &-img {
      width: var(--size-24px);
    }
    &-button {
      padding: var(--size-13px) var(--size-30px);
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
    }
    &-text {
      margin: 0;
      font-weight: 600;
      font-size: var(--size-16px);
      color: #000000;

      &.manager-mode {
        color: #c07c99;
      }
    }
  }
  &-version {
    padding-bottom: var(--size-20px);
    padding-left: var(--size-26px);
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: var(--size-14px);
    color: #03799a;
  }
  &-user {
    padding: 0 var(--size-30px) var(--size-13px);
    display: flex;
    flex-wrap: wrap;
    gap: var(--size-10px) var(--size-16px);
    justify-content: space-between;
    align-items: center;
    &-name {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: var(--size-14px);
      color: #03799a;
    }
    &-link {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: var(--size-14px);
      color: #039be5;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
