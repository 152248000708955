@import "../../../../styles/mixins";

.up {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--size-20px);

    &-link {
      cursor: pointer;
      margin: 0 var(--size-10px);
    }

    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
    }
    &-chip {
      margin: 0 var(--size-10px);
      color: #707a8d;
      background-color: #efe7ff;
      font-size: var(--size-14px);
      height: var(--size-28px);
      &-label {
        padding: 0 var(--size-10px);
      }
    }
    &-add,
    &-camera,
    &-help {
      border: 1px solid #e3edff;
      background-color: #fff;
      border-radius: var(--size-5px);
      padding: var(--size-4px);
      margin-right: var(--size-16px);
      color: #2b3f6c;
      label {
        display: flex;
      }
    }
  }
  &-grid {
    display: flex;
    margin-left: var(--size--4px);
    width: 100%;
    padding-bottom: var(--size-10px);
    padding-right: var(--size-10px);
    overflow-x: auto;
    &-item {
      width: calc(33% - var(--size-8px));
      flex-shrink: 0;
      margin: 0 var(--size-4px);
      position: relative;
      display: flex;
      flex-direction: column;
      > img {
        width: 100%;
        height: 100%;
        object-position: center center;
      }
      &-title {
        padding: 0 0 var(--size-5px);
        height: var(--size-20px);
        font-weight: 400;
        font-size: var(--size-12px);
        color: #aaaaaa;
      }
    }
    &-no-items {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #333333;
    }
  }
  &-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-img {
      max-width: 80vw;
      max-height: 80vh;
      width: calc(100% - var(--size-20px));
      object-fit: contain;
      margin: var(--size-10px);
      &:focus-visible {
        outline: none !important;
      }
    }
    &-delete {
      font-size: var(--size-30px);
      margin-left: auto;
    }
  }
  &-modal-camera {
    width: 80vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-drawer {
    height: 80vh;
    border-radius: var(--size-20px) var(--size-20px) 0 0;
    background-color: #fbfbfb;
    overflow: hidden;
    @include fixedContainer;
  }
  &-puller {
    position: absolute;
    width: 2.75rem;
    height: var(--size-3px);
    top: 0;
    right: 0;
    background: #e5e5e5;
    border-radius: var(--size-20px);
    top: var(--size-14px);
    margin: auto;
    left: 0;
    right: 0;
    cursor: pointer;
  }
  &-swipeable {
    padding: var(--size-20px);
    &-grid {
      overflow: auto;
      max-height: calc(80vh - var(--size-40px));
    }
    &-item {
      padding: var(--size-10px);
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-tooltip {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      text-align: left;
      margin-bottom: var(--size-4px);
      font-size: var(--size-10px);
    }
    &-icon {
      font-size: var(--size-16px);
      margin-right: var(--size-4px);
      &.green {
        color: #38bb98;
      }
      &.red {
        color: #ff836d;
      }
      &.grey {
        color: #999;
      }
    }
  }
}
