@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-card {
    padding: 3.438rem var(--size-16px) var(--size-22px);
    margin-bottom: var(--size-20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(179.89deg, #e3edff 21.91%, #d2f7ff 99.65%);
    &-avatar {
      margin-bottom: var(--size-13px);
    }
  }
  &-form {
    &-name {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }

    &-phones,
    &-links {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
        padding: var(--size-10px) var(--size-14px) !important;
      }
      &-root {
        margin-bottom: var(--size-10px);
      }
      &-add {
        color: #304378;
        border-color: #e3edff;
        background: #fff;
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        width: 100%;
        justify-content: flex-start;
        text-transform: none;
        font-weight: 400;
        font-size: var(--size-14px);
        &:hover {
          border-left: 0;
          border-radius: 0;
          border-right: 0;
        }
      }
    }
    &-email {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-address {
      margin-bottom: var(--size-30px);
    }

    &-geolocation {
      margin-bottom: var(--size-40px);
    }
    &-test-mode {
      padding: 0 var(--size-20px) var(--size-20px);
    }

    &-working-hours {
      margin-bottom: var(--size-40px);
      display: flex;
      flex-direction: column;
      &-title {
        font-weight: 400;
        font-size: var(--size-12px);
        line-height: var(--size-20px);
        color: #aaaaaa;
        margin: 0 var(--size-16px) var(--size-8px);
      }
      &-block {
        background: #ffffff;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: var(--size-26px) var(--size-16px);
      }
    }
    &-test-time {
      padding-bottom: var(--size-40px);
      label {
        font-weight: 600;
        font-size: var(--size-20px);
        line-height: var(--size-20px);
        color: #000000;
        margin-bottom: var(--size-16px);
        padding: 0 var(--size-16px);
        display: flex;
      }
      &-block {
        padding: 0 var(--size-16px);
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        background-color: #fff;
      }
    }
    &-special-days {
      margin-bottom: var(--size-40px);
      display: flex;
      flex-direction: column;
      &-title {
        font-weight: 400;
        font-size: var(--size-12px);
        line-height: var(--size-20px);
        color: #aaaaaa;
        margin: 0 var(--size-16px) var(--size-8px);
      }
    }
  }
}
