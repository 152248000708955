@import "../../../../styles/details";
@import "../../../../styles/mixins";

.up {
  &-card {
    padding: var(--size-65px) var(--size-16px) var(--size-22px);
    margin-bottom: var(--size-20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(179.89deg, #e3edff 21.91%, #f4f2ff 99.65%);
    position: relative;

    &-edit {
      @include fixedContainer;
      position: absolute;
      top: var(--size-56px);
      display: flex;
      justify-content: flex-end;
      padding-right: var(--size-16px);

      &-button {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-14px);
        color: #039be5;
        padding: 0 var(--size-3px);
        text-transform: none;
        min-width: 0;
        flex-shrink: 0;
      }
    }
    &-priority {
      position: absolute;
      left: var(--size-16px);
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: var(--size-14px);
      padding: 0 var(--size-3px);
      text-transform: none;
      min-width: 0;
      flex-shrink: 0;
      top: 3.563rem;
    }

    &-avatar {
      margin-bottom: var(--size-13px);
      &-none {
        width: 3.813rem;
      }
    }
    &-name {
      margin-bottom: var(--size-9px);
      font-weight: 700;
      font-size: var(--size-20px);
      line-height: var(--size-24px);
      color: #333333;
    }
    &-blocked {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      text-align: center;
      color: #c82727;
    }
    &.blocked {
      background: linear-gradient(
        359.33deg,
        rgba(229, 30, 42, 0.05) 0.62%,
        rgba(255, 255, 255, 0.05) 68.5%
      );
    }
  }
  &-buttons {
    padding: 0 var(--size-10px);
    color: #304378;
    display: flex;
    justify-content: flex-start;
    margin-bottom: var(--size-26px);
    flex-wrap: wrap;

    &-button {
      margin: 0 var(--size-6px);
      min-width: 0;
      padding: var(--size-8px) 4%;
      border-color: #AFBEDE;
      border-radius: var(--size-8px);
      font-size: var(--size-14px);
      text-transform: initial;
      line-height: var(--size-18px);
      background-color: #fff;
      margin-bottom: var(--size-10px);
      &.unskip {
        color: #00b383;
        border-color: #b5e5d8;
      }
    }
  }
  &-content {
    @include up-content-details;
  }
  &-w-full {
    width: 100%;
  }
}
