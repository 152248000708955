@import "../../../styles/mixins";

.up {
  &-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    z-index: 1100;
    background-color: #fff;
    &-error {
      padding-top: 15%;
      text-align: center;
      margin-top: var(--size-50px);
    }
  }

  &-drawer {
    min-height: 50%;
    border-radius: var(--size-20px) var(--size-20px) 0 0;
    background-color: #fbfbfb;
    @include fixedContainer;
    &-content {
      padding: var(--size-50px) var(--size-20px);
    }
  }

  &-puller {
    position: absolute;
    width: 2.75rem;
    height: var(--size-3px);
    top: 0;
    right: 0;
    background: #e5e5e5;
    border-radius: var(--size-20px);
    top: var(--size-14px);
    margin: auto;
    left: 0;
    right: 0;
  }

  &-header--agent-mode {
    color: #c07c99;
  }
  &-card-tasks {
    display: flex;
    margin-left: var(--size--4px);
    width: 100%;
    overflow-y: auto;
    padding-bottom: var(--size-10px);
    padding-right: var(--size-10px);
    &-title {
      padding-top: var(--size-30px);
      padding-bottom: var(--size-16px);
      font-weight: 600;
      font-size: var(--size-18px);
      line-height: var(--size-20px);
      color: #000000;
    }
  }
}
