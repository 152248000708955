@import "../../../styles/mixins";
.up-header {
  color: #304378;
  border-bottom: 1px solid #e3edff;
  box-shadow: none;
  padding: var(--size-2px) var(--size-8px);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: backgroundColor 0.2s linear 0s;
  @include fixedContainer;

  &--back-btn {
    padding: var(--size-8px) var(--size-8px) var(--size-8px) var(--size-4px);
    &:hover {
      border-radius: var(--size-8px);
    }
    svg {
      font-size: var(--size-14px)!important;
    }
    &-txt {
      color: #304378;
      font-size: var(--size-14px);
      font-family:
        Lato,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
    }
  }

  &-title {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8.5rem;
    font-weight: 700;
    font-size: var(--size-14px);
    line-height: var(--size-16px);
    color: #333333;
    text-align: center;
  }
  &-icons {
    display: flex;
    align-items: center;
    button {
      padding: var(--size-4px);
    }
  }
  &-left {
    display: flex;
    align-items: center;
  }
}
