.up {
  &-container {
    padding-top: var(--size-65px);
    padding-bottom: var(--size-40px);
  }
  &-locations {
    padding: 0 var(--size-16px);
    &-selected {
      font-weight: 400;
      font-size: var(--size-12px);
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      padding: var(--size-11px) var(--size-16px);
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: var(--size-18px);
      color: #707070;
      opacity: 0.5;
    }
  }
}
