.up-map-marker--circle {
  cursor: pointer;

  &-label {
    position: absolute;
    width: 6.25rem;
    white-space: nowrap;
    top: 1px;
    left: var(--size-24px);

    &--primary {
      --map-marker--label-color--primary: black;
      color: var(--map-marker--label-color--primary);
      text-shadow:
        1px 1px 1px white,
        -1px 1px 1px white,
        1px -1px 1px white,
        -1px -1px 1px white;
      font-size: var(--size-14px);
      font-family:
        Lato,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
      font-weight: 800;
    }

    &--secondary {
      --map-marker--label-color--secondary: black;
      color: var(--map-marker--label-color--secondary);
      font-size: var(--size-12px);
      font-family:
        Lato,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
      font-weight: 600;
    }
  }
}
