@import "../../../../styles/mixins";
.up-header {
  background: #fafafc;
  color: #333333;
  box-shadow: none;
  padding: var(--size-17px) var(--size-14px);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--size-48px);
  transition: 0.2s linear 0s;
  @include fixedContainer;
  border-bottom: 1px solid transparent;
  &-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-weight: 700;
    font-size: var(--size-12px);
    line-height: var(--size-16px);
    color: #333333;
    text-align: center;
  }
  &-button {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--size-14px);
    line-height: var(--size-19px);
    color: #039be5;
    padding: 0 var(--size-3px);
    text-transform: none;
    min-width: auto;
  }
  &-delete {
    margin-left: auto;
    margin-right: var(--size-10px);
  }
}
