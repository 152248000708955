@import "../../../../styles/mixins";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-container {
    padding-top: var(--size-65px);
    padding-bottom: var(--size-40px);
  }
  &-radio {
    &-group {
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      padding: var(--size-4px) 1.938rem;
      flex-wrap: wrap;
      margin-bottom: var(--size-30px);
    }
    &-label {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #707070;
    }
  }
  &-publish {
    &-visits {
      margin: 0 var(--size-16px) var(--size-8px);
      font-weight: 300;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #000000;
      background: #ffffff;
      border: 1px solid #f4f2ff;
      box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
      border-radius: var(--size-5px);
      padding: var(--size-12px);
      transition: backgroundColor 0.2s linear 0s;
      cursor: pointer;
      &.active {
        background-color: #e9f9df;
      }
    }

    &-tasks {
      margin-bottom: var(--size-8px);
      font-weight: 300;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #000000;
      background: #ffffff;
      border: 1px solid #f4f2ff;
      box-shadow: 0 var(--size-4px) var(--size-10px) rgba(112, 122, 141, 0.05);
      border-radius: var(--size-5px);
      padding: var(--size-12px);
      transition: backgroundColor 0.2s linear 0s;
      cursor: pointer;
      &.active {
        background-color: #e9f9df;
      }
    }
    &-selected {
      font-weight: 400;
      font-size: var(--size-12px);
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      padding: var(--size-11px) var(--size-16px);
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: var(--size-18px);
      color: #707070;
      opacity: 0.5;
    }
  }
  &-search {
    @include searchStyles;
  }
  &-form {
    &-note {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-location {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-visit {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-task {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
  }
  &-modal {
    background-color: #fafafc;
    height: 100vh;
    @include fixedContainer;
    &-content {
      padding: var(--size-16px);
    }
  }
}
