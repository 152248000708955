:root {
  --size-1px: 0.063rem;
  --size-2px: 0.125rem;
  --size-3px: 0.188rem;
  --size-4px: 0.25rem;
  --size-5px: 0.313rem;
  --size-6px: 0.375rem;
  --size-7px: 0.438rem;
  --size-8px: 0.5rem;
  --size-9px: 0.563rem;
  --size-10px: 0.625rem;
  --size-11px: 0.688rem;
  --size-12px: 0.75rem;
  --size-13px: 0.813rem;
  --size-14px: 0.875rem;
  --size-15px: 0.938rem;
  --size-16px: 1rem;
  --size-17px: 1.063rem;
  --size-18px: 1.125rem;
  --size-19px: 1.188rem;
  --size-20px: 1.25rem;
  --size-21px: 1.313rem;
  --size-22px: 1.375rem;
  --size-23px: 1.438rem;
  --size-24px: 1.5rem;
  --size-25px: 1.563rem;
  --size-26px: 1.625rem;
  --size-27px: 1.688rem;
  --size-28px: 1.75rem;
  --size-29px: 1.813rem;
  --size-30px: 1.875rem;
  --size-32px: 2rem;
  --size-36px: 2.25rem;
  --size-40px: 2.5rem;
  --size-48px: 3rem;  
  --size-50px: 3.125rem;
  --size-56px: 3.5rem;
  --size-65px: 4.063rem;
  --size--3px: -0.188rem;
  --size--4px: -0.25rem;
  --size--5px: -0.313rem;
  --size--9px: -0.563rem;
  --size--11px: -0.688rem;
  --size--14px: -0.875rem;
  --size--16px: -1rem;
  --size--18px: -1.125rem;
}
