.Toastify {
  & .Toastify__toast-container--top-center {
    top: 0;
  }

  & .Toastify__toast-container {
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    max-width: 56.25rem;
    padding: 0;
    margin: 0;
  }
  & &__toast--close-on-click {
    padding: var(--size-8px);
  }
  & &__toast-body {
    padding: var(--size-6px);
    align-items: flex-start;
  }
  & &__toast {
    min-height: auto;
    max-height: auto;
  }
  & &__toast-icon {
    width: var(--size-20px);
    margin-inline-end: var(--size-10px);
  }
  & &__progress-bar {
    height: var(--size-5px);
  }
  & &__close-button {
    display: flex;
   & > svg {
      width: var(--size-16px);
      height: var(--size-16px);
    }
  }
}
