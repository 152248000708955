.up {
  &-tabs {
    margin-bottom: var(--size-20px);
    min-height: auto;

    &-indicator {
      display: none;
    }

    &-three-columns {
      justify-content: space-between;
    }
    &-one-two-columns {
      gap: var(--size-10px);
    }
  }
  &-tab {
    flex-shrink: 1;
    width: 32%;
    min-width: initial;
    max-width: initial;
    min-height: 4.5rem;
    padding: var(--size-7px);
    border: 1px solid #e3edff;
    background: #ffffff;
    border-radius: var(--size-5px);
    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      &-icon {
        width: 2.063rem;
        height: 2.063rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
          width: var(--size-18px);
        }
        &.active {
          background-color: #e3edff;
        }
        &.completed {
          background-color: #e3ffe6;
        }
        &.skipped {
          background-color: #ffeae3;
        }
      }
      &-count {
        font-weight: 500;
        font-size: var(--size-24px);
        color: #333333;
        padding: 0 var(--size-8px);
      }
      &-title {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: var(--size-14px);
        color: #8495b9;
        text-align: start;
        text-transform: capitalize;
        margin-top: var(--size-10px);
      }
    }

    &-active {
      background: linear-gradient(98.61deg, #4d6ca9 12.77%, #217dba 86.01%);
      .up-tab-content {
        &-count {
          color: #fff;
        }
        &-title {
          color: #fff;
        }
        &-icon {
          background-color: transparent;
        }
      }
    }
  }
  &-card {
    margin-bottom: var(--size-20px);
  }
}
