.up {
  &-icon {
    color: #8f9bb3;
    min-width: 0;
    padding: 0;
    border-color: #c3d7ff;
    background-color: #fff;
    border-radius: var(--size-8px);
    height: var(--size-36px);
    width: var(--size-36px);
    margin: 0 var(--size-4px);
    & > svg {
      font-size: var(--size-16px);
    }
  }
  &-modal {
    padding-top: var(--size-20px);
    width: 21rem;
  }
}
.active-day {
  background-color: #e2e2e2 !important;
  &-red {
    background-color: rgba(255,131,109, 0.5) !important;
  }
}
.active-day-loading {
  display: none;
}
:global(.MuiDayPicker-weekContainer):first-child {
  .active-day-loading {
    &:first-child {
      width: 100%;
      height: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
