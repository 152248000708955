@import "../../../../styles/mui-switch";

@mixin inputStyles {
  line-height: 1.2;
  border: 0px;
  border-radius: 0px;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-container {
    padding: var(--size-65px) 0 var(--size-25px);
  }
  &-title {
    margin-bottom: var(--size-20px);
    &-input {
      @include inputStyles;
      font-size: var(--size-14px);
      padding: var(--size-16px);
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--size-30px);
    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: var(--size-12px);
      line-height: var(--size-20px);
      color: #aaaaaa;
      padding: 0 var(--size-16px);
      margin-bottom: var(--size-10px);
    }
    &-block {
      padding: var(--size-16px) 0 var(--size-6px);
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
    &-date-picker {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: var(--size-30px);
    }
    &-checkbox {
      &-block {
        display: flex;
        flex-direction: column;
      }
      &-row {
        display: flex;
        align-items: center;
        padding: var(--size-5px) var(--size-16px) var(--size-20px);
      }
      &-title {
        font-weight: 600;
        font-size: var(--size-14px);
        line-height: var(--size-16px);
        color: #000000;
        margin-right: var(--size-10px);
      }
    }
    &-time {
      &-block {
        padding: var(--size-16px);
        border-bottom: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
      }
      &-tabs {
        display: flex;
        margin-bottom: var(--size-16px);
        &-block {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        &-label {
          font-weight: 400;
          font-size: var(--size-12px);
          line-height: var(--size-20px);
          margin-bottom: var(--size-5px);
          color: #aaaaaa;
        }
        &-field {
          padding: var(--size-9px) var(--size-14px);
          background: #f4f5f7;
          border-radius: var(--size-5px);
          font-weight: 400;
          font-size: var(--size-14px);
          line-height: var(--size-16px);
          color: #707070;
          transition: 0.2s linear 0s;
          cursor: pointer;
          &.active {
            background-color: #e3edff;
            color: #000;
          }
        }
        &-divider {
          font-weight: 400;
          font-size: var(--size-32px);
          line-height: 1;
          text-align: center;
          color: #000000;
          margin: var(--size-24px) var(--size-16px) 0;
        }
      }
    }
    &-working-hours {
      padding: 0 var(--size-16px) var(--size-16px);
    }
  }
}
.up-switch {
  @include mui-switch;
}
