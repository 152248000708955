.up {
  &-container {
    padding: var(--size-65px) var(--size-16px) var(--size-20px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-link-wrapper a {
    display: block;
    margin-bottom: 1em;
    cursor: pointer;
  }
  &-info {
    padding-top: var(--size-50px);
    padding-bottom: var(--size-20px);
    margin-top: auto;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: var(--size-14px);
    color: #03799a;
  }
}
