@import "../../../../styles/mixins";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}

.up {
  &-radio {
    &-group {
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      padding: var(--size-4px) var(--size-30px);
      flex-wrap: wrap;
      margin-bottom: var(--size-30px);
    }
    &-label {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #707070;
    }
  }
  &-publish {
    &-selected {
      font-weight: 400;
      font-size: var(--size-12px);
      background: #ffffff;
      border-top: 1px solid #e0e0e0;
      padding: var(--size-11px) var(--size-16px);
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: var(--size-18px);
      color: #707070;
      opacity: 0.5;
    }
  }
  &-search {
    @include searchStyles;
  }
  &-form {
    &-note {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-location {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
  }
  &-modal {
    background-color: #fafafc;
    @include fixedContainer;
    height: 100vh;
    &-content {
      padding: var(--size-16px);
    }
  }
}
