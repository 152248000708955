.up {
  &-form {
    width: 100%;
    &-title {
      font-weight: 400;
      line-height: var(--size-20px);
      color: #aaaaaa;
      margin: 0 var(--size-16px) var(--size-8px);
    }
    &-input {
      border-radius: 0;
      background-color: #fff;
      color: #000000;
      &:before,
      &:after {
        display: none;
      }
      padding: var(--size-16px);
    }
  }
  &-menu {
    max-height: 12.5rem;
    &-item {
      display: block;
      width: 100%;
      color: #000;
      text-transform: inherit;
      text-align: start;
      min-height: var(--size-48px);
      padding-top: var(--size-6px);
      padding-bottom: var(--size-6px);
      box-sizing: border-box;
      white-space: nowrap;
      padding-left: var(--size-16px);
      padding-right: var(--size-16px);
      &.active {
        background-color: rgba(3, 155, 229, 0.08);
      }
    }
  }
}
