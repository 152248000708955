.up-container {
  padding: 0 !important;
  background: #fafafc;
  min-height: 100vh;
  max-width: 56.25rem;
  display: flex;
  flex-direction: column;
}
.up-link {
  cursor: pointer;
  text-decoration: underline;
  list-style: disc;
}
.up-appointment-group {
  padding: 0 var(--size-20px) 0;
  margin-bottom: var(--size-20px);
}
