@import "../../../../styles/mixins";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-form {
    &-title {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-products {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-description {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-note {
      &-input {
        @include inputStyles;
      }
    }
    &-location {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-deadline {
      width: 100%;
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-priority {
      margin-bottom: var(--size-40px);
      width: 100%;
      &-input {
        @include inputStyles;
      }
    }
    &-placeholder {
      color: #949494;
      font-size: var(--size-14px);
    }
  }
  &-modal {
    background-color: #fafafc;
    height: 100vh;
    @include fixedContainer;
    &-content {
      height: calc(100% - var(--size-48px));
      padding: var(--size-16px);
    }
  }
}
