.mat-calendar,
.MuiDialogContent-root {
  .MuiPickerStaticWrapper-content {
    width: 100%;
    min-width: auto;
    background: transparent;
  }

  .MuiPickersDay-root {
    width: var(--size-30px);
    height: var(--size-30px);
    font-weight: 400;
    font-size: var(--size-14px);
    color: #222b45;
    background-color: transparent;
    &.Mui-disabled {
      color: #999;
    }
    &.Mui-selected {
      background-color: rgba(35, 234, 79, 0.4) !important;
    }
  }

  .MuiDayPicker-slideTransition {
    min-height: 15rem;
  }
  // content
  .MuiDayPicker {
    &-header {
      margin-bottom: var(--size-16px);
      justify-content: space-between;
    }
    &-weekDayLabel {
      font-style: normal;
      font-weight: 500;
      font-size: var(--size-13px);
      color: #b2b5be;
      height: auto;
      width: var(--size-30px);
    }
    &-weekContainer {
      justify-content: space-between;
      margin-bottom: var(--size-8px);
    }
  }
}
.MuiCalendarOrClockPicker {
  &-root {
    & > div {
      width: 100%;
    }
    .MuiPickersToolbar-root {
      padding: var(--size-16px) var(--size-24px);
    }

    .MuiCalendarPicker-root {
      width: 100%;
      padding: 0 var(--size-16px);
    }
    .MuiPickersCalendarHeader {
      &-root {
        padding: 0 var(--size-10px);
        margin: 0 0 var(--size-30px);
        height: auto;
        max-height: initial;
        min-height: auto;
      }
      // title
      &-label {
        margin-right: var(--size-6px);
        font-weight: 700;
        font-size: var(--size-24px);
        line-height: 1.1;
        color: #333333;
      }
      &-labelContainer {
        height: auto;
        max-height: initial;
        overflow: visible;
      }
      &-switchViewButton {
        display: none;
      }
    }
    // nav buttons
    .MuiPickersArrowSwitcher-button {
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #c4d7ff;
      border-radius: var(--size-8px);
      color: #8f9bb3;
      padding: 0;
      margin: 0;
      > svg {
        font-size: 2.1875rem;
      }
    }
    .MuiPickersArrowSwitcher-spacer {
      width: var(--size-14px);
    }
  }
  &-mobileKeyboardInputView {
    padding: var(--size-16px) var(--size-24px);
  }
}
.MuiDialog-root .MuiDialog-paper {
  min-width: auto;
}
.MuiPickersToolbar-root {
  padding: var(--size-16px) var(--size-24px);
}
