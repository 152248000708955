@import "../../../../styles/mixins";

.up {
  &-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &-header {
    margin-bottom: var(--size-20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
    }
  }

  &-title {
    font-weight: 700;
    font-size: var(--size-20px);
    line-height: 2.313rem;
    color: #333333;
    margin-right: var(--size-48px);
    white-space: nowrap;

    .weekend {
      color: #ff836d;
    }

    &--today-icon {
      color: #277bb8;
      height: var(--size-24px);
      cursor: pointer;
      position: relative;

      & svg {
        position: absolute;
        top: 0;
        left: var(--size-5px);
      }
    }
  }

  &-button {
    color: #8f9bb3;
    min-width: 0;
    padding: 0;
    border-color: #c3d7ff;
    background-color: #fff;
    border-radius: var(--size-8px);
    height: var(--size-36px);
    width: var(--size-36px);
    flex-shrink: 0;
    & > svg {
      font-size: 2.1875rem !important;
    }
  }

  &-card {
    margin-bottom: var(--size-20px);
  }

  &-route {
    background: #4d6ca9;
    box-shadow: 0 var(--size-5px) var(--size-10px) #8f9bb3;
    font-weight: 700;
    font-size: var(--size-14px);
    line-height: var(--size-16px);
    text-transform: uppercase;
    color: #ffffff;
    padding: var(--size-15px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 0;
    @include fixedContainer;

    &:hover {
      background: #4d6ca9;
      box-shadow: 0 var(--size-5px) var(--size-10px) #8f9bb3;
    }
    &-spacer {
      height: var(--size-50px);
    }
  }

  &-route-start {
    background: #fafafc;
    border: 1px solid #e3edff;
    box-shadow: none;
    border-radius: var(--size-5px);
    width: 100%;
    padding: var(--size-8px);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--size-20px);
    font-weight: 400;
    font-size: var(--size-12px);
    line-height: var(--size-20px);

    &-content {
      padding: var(--size-4px) var(--size-8px) !important;
      margin-bottom: var(--size-6px);
      display: flex;
      flex-direction: column;
    }

    &-actions {
      padding: 0 var(--size-8px);
      display: flex;
      justify-content: flex-end;
    }

    &-button {
      border: 1px solid #e3edff;
      border-radius: 1000px;
      padding: var(--size-5px) 5%;
      font-weight: 600;
      font-size: var(--size-14px);
      line-height: var(--size-16px);
      color: #707a8d;
      text-transform: none;
      white-space: nowrap;
      margin: 0 0 0 var(--size-8px);
      min-width: 0;
    }
  }
  &-passed {
    &-title {
      font-weight: 600;
      font-size: var(--size-14px);
      color: #ff836d;
      margin-bottom: var(--size-8px);
      text-align: center;
    }
  }
}
