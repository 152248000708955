@import "../../../../styles/mui-switch";
@import "../../../../styles/mixins";

@mixin inputStyles {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  font-weight: 400;
  color: #000000;
  min-height: var(--size-56px);
  &:before,
  &:after {
    display: none;
  }
}
.up {
  &-card {
    padding: var(--size-56px) var(--size-16px) var(--size-22px);
    margin-bottom: var(--size-30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(179.89deg, #e3edff 21.91%, #d2f7ff 99.65%);
  }
  &-form {
    &-name {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }
    &-last-name {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
      }
    }

    &-phoneNumbers,
    &-emails,
    &-links {
      margin-bottom: var(--size-40px);
      &-input {
        @include inputStyles;
        padding: var(--size-10px) var(--size-14px) !important;
      }
      &-root {
        margin-bottom: var(--size-10px);
      }
      &-add {
        color: #304378;
        border-color: #afbede;
        background: #fff;
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        width: 100%;
        justify-content: flex-start;
        text-transform: none;
        font-weight: 400;
        font-size: var(--size-14px);
        &:hover {
          border-left: 0;
          border-radius: 0;
          border-right: 0;
        }
      }
    }
    &-address {
      margin-bottom: var(--size-30px);
      &-switch {
        margin-bottom: var(--size-14px);
        padding: 0 var(--size-16px);
        &-label {
          display: inline-block;
          font-style: normal;
          font-weight: 600;
          font-size: var(--size-14px);
          line-height: var(--size-16px);
          color: #000000;
          padding-right: var(--size-14px);
        }
      }
    }
    &-company {
      margin-bottom: var(--size-30px);
      &-input {
        @include inputStyles;
      }
    }
    &-location {
      margin-bottom: var(--size-30px);
      &-input {
        @include inputStyles;
      }
    }
  }
  &-modal {
    &-location {
      background-color: #fafafc;
      height: 100vh;
      &-content {
        padding: var(--size-16px);
      }
    }
    @include fixedContainer;
    background-color: #fafafc;
    height: 100vh;
    overflow: auto;
    &-company {
      &-item {
        font-weight: 700;
        font-size: var(--size-14px);
        line-height: var(--size-17px);
        color: #333333;
        padding: var(--size-16px);
        border-top: 1px solid #e0e0e0;
        &:last-child {
          border-bottom: 1px solid #e0e0e0;
        }
        &.active {
          background-color: #e9f9df;
        }
      }
    }
  }
}
.up-switch {
  margin-left: auto;
  @include mui-switch;
}
