.up {
  &-container {
    display: flex;
    align-items: center;
    margin-top: var(--size-6px);
    height: var(--size-25px);
    flex-shrink: 0;
  }
  &-item {
    display: flex;
    align-items: start;
    cursor: pointer;
    &-icon {
      font-size: var(--size-25px);
      margin-right: var(--size-10px);
      &.green {
        color: #38bb98;
      }
      &.red {
        color: #ff836d;
      }
      &.grey {
        color: #999;
      }
    }
  }
}
