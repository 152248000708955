.range-calendar {
  width: 100%;
  font-family:
    Lato,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  .react-datepicker {
    width: 100%;
    padding: 0 var(--size-16px);
    border: none;
    &__header {
      width: 100%;
      background: transparent;
      text-align: start;
      border: none;
      padding: 0;
    }
    &__month-container {
      width: 100%;
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-20px);
        line-height: var(--size-20px);
        color: #000000;
        height: var(--size-65px);
        padding: var(--size-12px) 0;
      }
      .react-datepicker__day {
        &-names {
          margin-bottom: var(--size-16px);
          display: flex;
          justify-content: space-between;
        }
        &-name {
          font-style: normal;
          font-weight: 500;
          font-size: var(--size-13px);
          color: #b2b5be;
          height: auto;
          width: var(--size-30px);
          line-height: initial;
        }
      }
    }
    &__navigation {
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #c4d7ff;
      border-radius: var(--size-8px);
      color: #8f9bb3;
      padding: 0;
      margin: 0;
      height: var(--size-24px);
      width: var(--size-24px);
      top: var(--size-12px);

      &-icon {
        font-size: var(--size-20px);
        &:before {
          border-color: #8f9bb3;
          border-width: 2px 2px 0 0;
          content: "";
          display: block;
          height: var(--size-4px);
          position: absolute;
          top: var(--size-9px);
          width: var(--size-4px);
        }

        &--previous {
          right: -0.05rem;
          top: 0;
          &::before {
            right: var(--size--3px);
          }
        }
        &--next {
          top: 0;
          left: -0.05rem;
          &::before {
            left: var(--size--3px);
          }
        }
      }

      &--previous {
        left: auto;
        right: 3.438rem;
      }
      &--next {
        left: auto;
        right: var(--size-22px);
      }
    }
    &__month {
      margin: 0;
    }
    &__week {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--size-8px);
    }
    &__day {
      width: var(--size-28px);
      height: var(--size-28px);
      font-weight: 400;
      font-size: var(--size-12px);
      color: #222b45;
      border-radius: 50%;
      // background-color: transparent;
      &--outside-month {
        visibility: hidden;
        pointer-events: none;
      }
      &--today {
        color: #039be5;
      }
      &--disabled {
        color: #ccc;
      }
    }

    &__day--keyboard-selected,
    &__month-text--keyboard-selected,
    &__quarter-text--keyboard-selected,
    &__year-text--keyboard-selected {
      background-color: transparent !important;
    }

    &__day--selected,
    &__day--in-selecting-range,
    &__day--in-range,
    &__month-text--selected,
    &__month-text--in-selecting-range,
    &__month-text--in-range,
    &__quarter-text--selected,
    &__quarter-text--in-selecting-range,
    &__quarter-text--in-range,
    &__year-text--selected,
    &__year-text--in-selecting-range,
    &__year-text--in-range {
      background-color: rgba(35, 234, 79, 0.4) !important;
      &:hover {
        border-radius: 50%;
      }
    }
  }
}
