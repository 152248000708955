@import "../../../../styles/mui-switch";
@import "../../../../styles/mixins";
.up {
  &-drawer {
    height: 90%;
    border-radius: var(--size-20px) var(--size-20px) 0 0;
    background-color: #fbfbfb;
    @include fixedContainer;
  }
  &-container {
    padding: var(--size-20px);
    &-title {
      font-weight: 700;
      font-size: var(--size-20px);
      line-height: var(--size-24px);
      color: #333333;
      margin-bottom: var(--size-20px);
    }
    &-checkbox {
      margin-bottom: var(--size-20px);
      &-label {
        font-weight: 600;
        font-size: var(--size-14px);
        line-height: var(--size-16px);
        color: #000000;
      }
    }
  }
  &-datepicker {
    background: #ffffff;
    border: 1px solid #e3edff;
    border-radius: var(--size-5px);

    &-header {
      padding: var(--size-14px);
      display: flex;
      align-items: center;
      &-icon {
        width: var(--size-24px);
        margin-right: var(--size-10px);
      }
      &-title {
        font-weight: D 600;
        font-size: var(--size-14px);
        line-height: var(--size-16px);
        color: #000000;
      }
      .up-switch {
        margin-left: auto;
        @include mui-switch;
      }
    }
    &-item {
      padding: var(--size-10px);
    }
  }
}
