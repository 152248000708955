.up-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: var(--size-8px);

  &-button {
    border: 1px solid #e3edff;
    border-radius: 1000px;
    padding: var(--size-4px) var(--size-8px);
    font-weight: 600;
    font-size: var(--size-10px);
    line-height: var(--size-12px);
    color: #304378;
    text-transform: none;
    white-space: nowrap;
    min-width: 0;
  }
}
