.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    #d69848 0%,
    #efc676 34.17%,
    #f0b37e 67.16%,
    #e88baa 100%
  );
  padding-bottom: 10%;
  img {
    width: 100%;
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-bottom: 10%;
}