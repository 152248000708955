.MuiFormHelperText-root.Mui-error,
.MuiFormHelperText-root {
  color: #ff836d;
  width: 100%;
  margin: var(--size-4px) var(--size-10px);
  font-size: var(--size-12px);
  top: 100%;
}
.MuiOutlinedInput-notchedOutline {
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #e0e0e0 !important;
  top: var(--size--5px);
  padding: 0 var(--size-8px);
  border-radius: 0;
  & > legend {
    height: var(--size-11px);
    line-height: var(--size-11px);
    span {
      padding: 0 var(--size-5px);
      text-transform: capitalize;
    }
  }
}
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff836d !important;
}
@media screen and (max-width: 900px) {
  .MuiMenu-paper {
    left: 0 !important;
  }
}
.MuiAvatar-root {
  width: 6.125rem;
  height: 6.125rem;
}
.MuiButton-root {
  padding: var(--size-8px) var(--size-16px);
  min-width: 4rem;
}
.MuiButtonBase-root .MuiSvgIcon-root {
  font-size: var(--size-20px);
}
.MuiIconButton-root {
  padding: var(--size-8px);
  img {
    width: var(--size-20px);
  }
}
.MuiMenuItem-root,
.MuiAccordionSummary-root {
  padding: 0 var(--size-16px);
  min-height: var(--size-48px);
  &.Mui-expanded {
    min-height: 4rem;
  }
}
.MuiFormLabel-root {
  transform: translate(var(--size-14px), var(--size--9px)) scale(0.75);
  text-transform: capitalize;
}
.MuiButton-startIcon {
  margin-right: var(--size-8px);
  margin-left: var(--size--4px);
}
.MuiDialogContent-root {
  padding: var(--size-20px) var(--size-24px);
}
.MuiDialogActions-root {
  padding: var(--size-8px);
}
.MuiAccordionSummary-content {
  margin: var(--size-12px) 0;
  &.Mui-expanded {
    margin: var(--size-20px) 0;
  }
}
.MuiCheckbox-root,
.MuiRadio-root {
  padding: var(--size-9px);
}
.MuiAccordionDetails-root {
  padding: var(--size-8px) var(--size-16px) var(--size-16px);
}
.MuiFormControlLabel-root {
  margin-left: var(--size--11px);
  margin-right: var(--size-16px);
}
.MuiAccordion-root {
  margin: 0;
  &.Mui-expanded {
    margin: 0;
  }
}
.MuiSelect-select {
  padding: var(--size-16px);
}
.MuiInputAdornment-root {
  margin-left: var(--size-8px);
}
.MuiCircularProgress-root {
  width: var(--size-40px) !important;
  height: var(--size-40px) !important;
}
.MuiNativeSelect-select {
  padding: var(--size-16px) var(--size-14px);
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.MuiInputBase-hiddenLabel {
  padding: var(--size-16px) var(--size-14px);
}

.MuiTooltip-tooltip {
  font-size: var(--size-14px);
  margin-bottom: var(--size-16px) !important;
  margin-top: var(--size-24px);
  padding: var(--size-8px);
  min-width: 10rem;
  text-align: center;
  background-color: #e3edff;
  color: black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.MuiTooltip-arrow {
  color: #e3edff;
}