@mixin fixedContainer {
  max-width: 56.25rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
}
@mixin fabStyles {
  position: fixed;
  right: var(--size-16px);
  bottom: var(--size-16px);
  border: 1px solid #e3edff;
  background: #fff;
  box-shadow: 0 var(--size-4px) var(--size-4px) rgb(0 0 0 / 10%);
  color: #304378;
  width: 2.813rem;
  height: 2.813rem;
  min-height: auto;
}

@mixin searchStyles {
  &-root {
    font-size: var(--size-14px);
    padding: var(--size-7px) var(--size-16px);
    color: #555;
    border-radius: var(--size-50px);
    background: #f0f0f0;
    margin-bottom: var(--size-20px);
    &:before,
    &:after {
      display: none;
    }
  }
  &-input {
    padding: 0;
  }
  &-icon {
    color: #8f9bb3;
  }
}
