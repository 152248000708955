.picker {
  &-container {
    z-index: 1;
    width: 100%;

    &,
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
  }
  &-inner {
    position: relative;

    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 var(--size-20px);

    font-size: var(--size-20px);
    -webkit-mask-box-image: linear-gradient(
      to top,
      transparent,
      transparent 5%,
      white 20%,
      white 80%,
      transparent 95%,
      transparent
    );
  }

  &-column {
    flex: 1 1;

    position: relative;

    max-height: 100%;

    overflow: hidden;
    text-align: center;
    &:first-child {
      text-align: right;
    }
    &:nth-child(3) {
      text-align: left;
    }
  }
  &-scroller {
    transition: 300ms;
    transition-timing-function: ease-out;
    touch-action: none;
  }

  &-item {
    position: relative;
    height: 2.25rem !important;
    line-height: 2.25rem !important;
    padding: 0 var(--size-10px);

    white-space: nowrap;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &-selected {
      color: #222;
    }
  }

  &-highlight {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    pointer-events: none;
    background-color: #f4f4f4;
    z-index: -1;
    height: 2.25rem !important;
    margin-top: var(--size--18px) !important;
  }
}
