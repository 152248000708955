.up {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--size-20px);
    &-title {
      font-weight: 600;
      font-size: var(--size-20px);
      line-height: var(--size-20px);
      color: #000000;
    }
    &-chip {
      margin: 0 var(--size-10px);
      color: #707a8d;
      background-color: #efe7ff;
      font-size: var(--size-14px);
      height: var(--size-28px);
      &-label {
        padding: 0 var(--size-10px);
      }
    }
    &-add {
      border: 1px solid #e3edff;
      background-color: #fff;
      border-radius: var(--size-5px);
      padding: var(--size-2px);
      margin-right: var(--size-16px);
      margin-left: var(--size-16px);
      color: #2b3f6c;
    }
    &-link {
      cursor: pointer;
    }
  }
  &-grid {
    display: flex;
    margin-left: var(--size--4px);
    width: 100%;
    overflow-y: auto;
    padding-bottom: var(--size-10px);
    padding-right: var(--size-10px);

    &-no-items {
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-20px);
      color: #333333;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-button {
        color: #304378;
        background-color: #fff;
        border-color: #e3edff;
        margin-top: var(--size-16px);
        font-weight: 400;
        font-size: var(--size-14px);
        text-transform: capitalize;
      }
    }
  }
}
