@import "../../../../styles/details";
.up {
  &-container {
    padding-top:  var(--size-65px);
    padding-bottom: var(--size-40px);
  }
  &-content {
    @include up-content-details;
  }
  &-description {
    font-weight: 300;
    font-size: var(--size-14px);
    line-height: var(--size-20px);
    color: #000000;
    padding: 0 var(--size-16px);
  }
}
