.up {
  &-form {
    display: flex;
    flex-direction: column;

    &-textfield {
      margin-bottom: var(--size-20px);
    }

    &-notched-outlined {
      border-left: 1px solid #e0e0e0 !important;
      border-right: 1px solid #e0e0e0 !important;

      .Mui-focused & {
        border-width: 2px !important;
      }
    }

    &-button {
      margin: 0;
      min-width: 0;
      padding: var(--size-8px) 4%;
      border-radius: var(--size-8px);
      font-size: var(--size-14px);
      text-transform: initial;
      line-height: var(--size-18px);
      background: #4d6ca9;
      color: #fff;

      &:hover {
        background: #4d6ca9;
      }
    }
  }
}
.validation-error {
  padding-left: var(--size-20px);
  li {
    list-style-type: disc;
  }
}
