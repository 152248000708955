@mixin mui-switch {
  width: 2.375rem;
  height: 1.438rem;
  padding: 0;
  overflow: visible;
  &-track {
    display: none;
  }
  &-thumb {
    background-color: #fff;
    border: 2px solid #e6e6e6;
    border-radius: var(--size-50px);
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: none;

    &:after {
      content: "";
      position: absolute;
      left: -1px;
      width: var(--size-17px);
      height: var(--size-17px);
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      background-color: #ffffff;
      box-shadow: 0 0 var(--size-3px) rgb(0 0 0 / 10%);
      transition: 0.2s ease-in-out 0s;
      top: -1px;
    }
  }
  &-switch-base {
    padding: 1px;
    width: 100%;
    height: 100%;
    transform: translateX(0) !important;
    input {
      width: 100% !important;
      left: 0 !important;
    }
  }
  &-checked {
    .up-switch {
      &-thumb {
        background-color: #4cd964 !important;
        border-color: #4cd964 !important;
        &:after {
          left: calc(100% - var(--size-18px));
        }
      }
    }
  }
}
