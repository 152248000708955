@import "../../../../styles/details";

.up {
  &-card {
    padding: var(--size-56px) var(--size-16px) var(--size-22px);
    background: linear-gradient(
                    90.07deg,
                    rgba(97, 188, 248, 0.27) 21.36%,
                    rgba(255, 189, 244, 0.17) 99.93%
    );
    margin-bottom: var(--size-20px);

    &-title {
      font-weight: 700;
      font-size: var(--size-24px);
      line-height: var(--size-30px);
      color: #333333;
      margin-bottom: var(--size-30px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &-location {
        margin-bottom: var(--size-20px);
        font-weight: 700;
        font-size: var(--size-15px);
        line-height: var(--size-16px);
        color: #333333;
      }

      &-edit {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-14px);
        color: #039be5;
        padding: 0 var(--size-3px);
        text-transform: none;
        min-width: 0;
        flex-shrink: 0;
        margin-left: var(--size-10px);
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .address {
        font-size: var(--size-13px);
        line-height: var(--size-20px);
        color: #333333;
        margin-right: 20%;
      }

      &-button {
        background: #38bb98;
        color: #fff;
        padding: 0 var(--size-18px);
        font-style: normal;
        font-weight: 700;
        font-size: var(--size-14px);
        line-height: var(--size-28px);
        color: #f7fffd;
        text-transform: capitalize;
        flex-shrink: 0;
        box-shadow: none;
        line-height: 1.2;
        height: 2.188rem;

        &:hover {
          background: #38bb98;
        }
      }

      .status {
        font-weight: 400;
        font-size: var(--size-12px);
        line-height: var(--size-20px);
        color: #00b383;
        white-space: nowrap;
        text-align: right;

        img {
          margin-right: var(--size-5px);
        }
      }
    }
  }

  &-description {
    font-weight: 300;
    font-size: var(--size-14px);
    line-height: var(--size-20px);
    color: #000000;
  }

  &-content {
    @include up-content-details;
    &-value.high, &-value.missed {
      color: #fb0707;
    }
  }

  &-notes {
    padding-left: var(--size-16px);
    padding-bottom: var(--size-30px);
  }
}
