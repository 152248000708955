.up-agents {
  &-dialog-title {
    font-weight: 700;
    text-align: center;
    color: #333;
  }

  &-dialog-full-name {
    font-size: var(--size-20px);
    font-weight: 700;
    color: #c07c99;
  }

  &-dialog-actions {
    justify-content: space-evenly;
    gap: var(--size-5px);
    padding-bottom: var(--size-15px);

    & button {
      font-size: var(--size-14px);
      width: 6.875rem;
      height: 2.188rem;
      border-radius: var(--size-5px);
      font-weight: 800;
      margin: 0 !important;
    }

    &--cancel-btn {
      color: #304378;
      text-transform: capitalize;
      border: 1px solid #c3d7ff;
    }

    &--ok-btn {
      background: #304378;
      color: #fff;

      &:hover {
        background: #495983;
      }
    }
  }
}
