.up {
  &-container {
    display: flex;
    flex-direction: column;
  }
  &-content {
    margin-bottom: var(--size-30px);
    &:last-child {
      margin-bottom: 0;
      margin-top: var(--size-20px);
    }
  }
  &-row {
    display: flex;
    align-items: center;
    &-checkbox {
      margin-right: 5%;
      padding: 0;
    }
    &-title {
      margin-right: auto;
      font-weight: 600;
      font-size: var(--size-14px);
      line-height: var(--size-16px);
      color: #000000;
      text-transform: capitalize;
    }
    &-divider {
      font-weight: 400;
      font-size: var(--size-32px);
      line-height: 1;
      text-align: center;
      color: #000000;
      margin: 0 3%;
    }
    &-tab {
      padding: var(--size-9px) var(--size-14px);
      background: #f4f5f7;
      border-radius: var(--size-5px);
      font-weight: 400;
      font-size: var(--size-14px);
      line-height: var(--size-16px);
      color: #707070;
      transition: 0.2s linear 0s;
      min-height: 2.125rem;
      min-width: 5.563rem;
      cursor: pointer;
      &.active {
        background-color: #e3edff;
        color: #000;
      }
    }
  }
}
