.up {
  &-popover {
    padding: var(--size-4px) var(--size-16px);
  }
  &-button {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    text-transform: initial;
    font-size: var(--size-14px);
  }
}
