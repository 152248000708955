.up {
  &-notes,
  &-tasks,
  &-resources {
    padding-left: var(--size-16px);
    padding-bottom: var(--size-30px);
  }
  &-tab {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: var(--size-14px);
    color: #304378;
    padding: var(--size-12px) var(--size-8px);
    text-transform: none;
    min-width: 0;
    flex-shrink: 0;
    min-height: auto;
    width: 33%;
  }
  &-tabs {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--size-10px);
    margin-bottom: var(--size-20px);
    min-height: auto;
    border-bottom: 1px solid #e0e0e0;
  }
}
